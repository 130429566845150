import { GlobalPromotionList } from '../components/GlobalPromotion';

function GlobalPromotionListRoute() {
  return <GlobalPromotionList />;
}

export const Component = GlobalPromotionListRoute;

export function clientLoader() {
  return null;
}
